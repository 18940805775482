@import 'variables';
@import 'mixnis';

:root.day-theme {
  @include spread-map($day-theme);
}
:root.night-theme {
  @include spread-map($night-theme);
}
:root.green-theme {
  @include spread-map($green-theme);
}